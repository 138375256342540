import { post } from '@/common/utils/request';
import { EQueryResultType, IPostQueryReqParams } from './types';
import {
  EQueryStatus,
  IAbortQueryInfo,
  IQueryResult,
} from '../queryColumns/types';
import { getResRowsCount } from '../queryColumns';
import { IErrorResponse } from '@/typings';

export async function postQuery(params: IPostQueryParams) {
  const DEFAULT_LIMIT = 1000;
  const { limit: reqLimit = DEFAULT_LIMIT } = params;

  const queryInput: IPostQueryReqParams & { limit: number } = {
    ...params,
    queryResultType: params.queryResultType || EQueryResultType.SQL_AND_DATA,
    offset: 0,
    limit: reqLimit + 1,
  };

  try {
    const res = await post<IQueryResult>('/queryMetrics', queryInput, {
      silent: true,
      signal: params.signal,
    });
    const limit = queryInput.limit;

    // 根据返回的数据计算本次返回结果里的总行数
    const rowCount = getResRowsCount(res.table);
    const hasMore = rowCount > limit;

    return {
      status: EQueryStatus.SUCCESS,
      warning: res.warning || '',
      data: res.table,
      limit,
      rowCount,
      hasMore,
      sql: res.sql,
    };
  } catch (e) {
    console.error('runningCount request error', e);
    return {
      status: EQueryStatus.FAILED,
      warning: '',
      data: {},
      limit: queryInput.limit,
      rowCount: 0,
      hasMore: true,
      errorMsg: (e as IErrorResponse).errorMsg,
      detailErrorMsg: (e as IErrorResponse).detailErrorMsg,
    };
  }
}

/**
 * 取消正在进行的查询
 * 由于服务端不支持取消进行中的查询，因此只是前端断掉请求，防止出现时序问题及占满同域名的请求连接数
 */
export function cancel(abortQueryInfo: IAbortQueryInfo) {
  const { abortController } = abortQueryInfo;
  post(
    '/cancel',
    {
      queryId: abortQueryInfo.queryId,
    },
    { silent: true },
  );
  abortController.abort();
}

export interface IPostQueryParams extends IPostQueryReqParams {
  signal: AbortSignal; // 用于取消请求的信号
}
